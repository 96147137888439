import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./style.css";
import CoreuiVue from "@coreui/vue";
import BootstrapVue from "bootstrap-vue";
import "vue-datetime/dist/vue-datetime.css";
import VueCookies from "vue-cookies";
import axios from "axios";
import { library } from "@fortawesome/fontawesome-svg-core";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import InfiniteLoading from "vue-infinite-loading";

// const moment = require("moment");
require("moment/locale/th");

import {
  faPowerOff,
  faDiceD6,
  faPlusCircle,
  faMinusCircle,
  faSyncAlt,
  faBars,
  faTimes,
  faFileUpload,
  faFileDownload,
  faPen,
  faPenSquare,
  faEdit,
  faReceipt,
  faTruck,
  faExclamationCircle,
  faUser,
  faImage,
  faLocationArrow,
  faReply,
  faRobot,
  faSearch,
  faCartPlus,
  faClipboardList,
  faFilter,
  faChevronLeft,
  faMapMarkerAlt,
  faCheck,
  faCommentAlt,
  faCheckCircle,
  faExclamation,
  faPlus,
  faUserCircle,
  faUpload,
  faDownload,
  faComments,
  faSignOutAlt,
  faTimesCircle,
  faCommentDots,
  faTrash,
  faChevronCircleUp,
  faChevronCircleDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import vueNumeralFilterInstaller from "vue-numeral-filter";
import Vuelidate from "vuelidate";

// Install Plugin
Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(CoreuiVue);
Vue.use(VueCookies);
//Vue.$cookies.config("30d", null, null); //Local Config
//Vue.$cookies.config("30d", null, ".dosetech.co"); //Dev Config
//Vue.$cookies.config("30d", null, ".d3-dots.com"); //Prod Config
Vue.use(VueMoment, {
  moment,
});
Vue.use(InfiniteLoading, {
  /* options */
});

library.add(
  faPowerOff,
  faDiceD6,
  faPlusCircle,
  faMinusCircle,
  faSyncAlt,
  faBars,
  faTimes,
  faFileUpload,
  faFileDownload,
  faPen,
  faPenSquare,
  faEdit,
  faReceipt,
  faFileDownload,
  faTruck,
  faExclamationCircle,
  faUser,
  faImage,
  faLocationArrow,
  faReply,
  faRobot,
  faSearch,
  faCartPlus,
  faClipboardList,
  faFilter,
  faChevronLeft,
  faMapMarkerAlt,
  faCheck,
  faCommentAlt,
  faCheckCircle,
  faExclamation,
  faPlus,
  faUserCircle,
  faUpload,
  faDownload,
  faComments,
  faSignOutAlt,
  faTimesCircle,
  faCommentDots,
  faTrash,
  faChevronCircleUp,
  faChevronCircleDown
);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(vueNumeralFilterInstaller, { locale: "en-gb" });

Vue.config.productionTip = false;
let isMobile = function() {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
};

//Adding Instance
//Vue.prototype.$baseUrl = "https://cmg-chatshop-api.dosetech.co"; //DEV
//Vue.prototype.$baseUrl = "https://seller-api.d3-dots.com"; //PROD
Vue.prototype.$apiKey = "ChAtA7Ds40p";
Vue.prototype.$axios = axios;
Vue.prototype.$isMobile = isMobile();
Vue.prototype.$formatDate = "DD MMM YYYY";
Vue.prototype.$formatDateTime = "DD MMM YYYY HH:mm:ss";
Vue.prototype.$formatTime = "HH:mm";

const MODE = `${process.env.VUE_APP_MODE}`;
if (MODE === "DEV") {
  Vue.prototype.$baseUrl = "https://kerry-admin-api.d3-dots.com";
  Vue.prototype.$chatBaseUrl = "https://kerry-chat-agent-api.d3-dots.com/api";
  Vue.prototype.$chatBaseUrlSocket =
    "wss://cflsirhev1.execute-api.ap-southeast-1.amazonaws.com/uat";
  Vue.prototype.$backofficeUrl = "https://kerry-uat-backoffice.d3-dots.com/";

  //Vue.$cookies.config("30d", null, ".vercel.app");
} else if (MODE === "UAT") {
  Vue.prototype.$baseUrl = "https://kerry-admin-api.d3-dots.com";
  Vue.prototype.$chatBaseUrl =
    "https://kerry-uat-chat-agent-api.d3-dots.com/api";
  Vue.prototype.$chatBaseUrlSocket =
    "wss://cflsirhev1.execute-api.ap-southeast-1.amazonaws.com/uat";
  Vue.prototype.$backofficeUrl = "https://kerry-uat-backoffice.d3-dots.com/";

  //Vue.$cookies.config("30d", null, ".d3-dots.com");
} else if (MODE === "DEMO") {
  Vue.prototype.$baseUrl = "https://kerry-admin-api.d3-dots.com";
  Vue.prototype.$chatBaseUrl =
    "https://kerry-uat-chat-agent-api.d3-dots.com/api";
  Vue.prototype.$chatBaseUrlSocket =
    "wss://cflsirhev1.execute-api.ap-southeast-1.amazonaws.com/uat";
  Vue.prototype.$backofficeUrl = "https://kerry-uat-backoffice.d3-dots.com/";

  //Vue.$cookies.config("30d", null, ".vercel.app");
} else if (MODE === "PROD") {
  Vue.prototype.$baseUrl = "https://kerry-admin-api.d3-dots.com";
  Vue.prototype.$chatBaseUrl =
    "https://kerry-chat-agent-api.d3-dots.com/api";
  Vue.prototype.$chatBaseUrlSocket =
    "wss://cflsirhev1.execute-api.ap-southeast-1.amazonaws.com/production";
  Vue.prototype.$backofficeUrl = "https://kerry-backoffice.d3-dots.com/";
  //Vue.$cookies.config("30d", null, ".d3-dots.com");
}
Vue.prototype.$headersChat = {
  "API-KEY": "lDd0T2cHa7s9sT3M",
  "Content-Type": "application/json",
  culture: Vue.prototype.$language,
  Authorization: VueCookies.isKey("kerry-session-token")
    ? `Bearer ${VueCookies.get("kerry-session-token")}`
    : "null",
};

// Add a request interceptor
axios.interceptors.request.use(
  function(config) {
    var timestamp = Vue.$cookies.get("kerry-timestamp");
    var kerry_session_token = Vue.$cookies.get("kerry-session-token");
    if (kerry_session_token) {
      if (timestamp && store.state.timestamp) {
        if (timestamp > store.state.timestamp) {
          window.location.href = "/";
        }
      } else if (timestamp && !store.state.timestamp) {
        store.dispatch("setTimestamp", timestamp);
      }
    }
    const token = Vue.$cookies.isKey("kerry-session-token")
      ? Vue.$cookies.get("kerry-session-token")
      : "null";
    config.headers = {
      "API-KEY": "ChAtA7Ds40p",
      Authorization: "Bearer " + token,
    };
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response.status == 401) {
      // router.push({ path: "/login" });
      window.location.href = Vue.prototype.$backofficeUrl + "/login";
    } else if (error.response.status == 403) {
      // router.push({ path: "/AccessDenied" });
      window.location.href = Vue.prototype.$backofficeUrl + "/login";
    }
    return Promise.reject(error);
  }
);

//Add route permisssion
router.beforeEach((to, from, next) => {
  if (to.name == "Chat" && !VueCookies.get("kerry-session-token")) {
    window.location.href = Vue.prototype.$backofficeUrl + "/login";
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // if (!VueCookies.get("kerry-session-token")) {
    //   next({
    //     path: "/login",
    //   });
    // } else {
    next();
    // }
  } else if (to.name == "Login" && VueCookies.get("kerry-session-token")) {
    next({
      path: "/",
    });
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
